import React, { useEffect, useState, useContext } from 'react';
import {
    Container,
    Hidden,
    Button,
    Box,
    IconButton,
    Drawer,
    useTheme,
    MenuItem,
    Typography,
    Link as MuiLink,
    Select,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAccount } from 'wagmi';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { DataContext } from '../utils/ContextAPI';
import ConnectionModal from './Modals/ConnectionModal';
import DisConnectModal from './Modals/DisConnectModal';
import logo from '../assets/images/logo.png';
import sol from '../assets/images/sol.png';
import doge from '../assets/images/doge.png';
import quai from '../assets/images/quai.png';
import { Edit, Edit2, Edit3, User, UserPlus } from 'react-feather';
import { EditAttributesOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const subMenus = [
    {
        name: 'Solana',
        to: 'https://swap.solardex.finance',
        logo: sol,
    },
    {
        name: 'Quai',
        to: 'https://solardex-quai.netlify.app/',
        logo: quai,
    },
    {
        name: 'DogeChain',
        to: 'https://solarshepardswap.solardex.finance/',
        logo: doge,
    },
    {
        name: 'EVMChains',
        to: 'https://solar-evmchains.up.railway.app',
        logo: logo,
    },
    {
        name: 'CrossChain',
        to: 'https://solar-crosschains.up.railway.app',
        logo: logo,
    },
];

const Header = ({ mode, toggleMode }) => {
    const { openProfile, setOpenProfile } = useContext(DataContext);

    const [connectModal, setConnectModal] = useState(false);
    const [disconnectModal, setDisconnectModal] = useState(false);
    const [renderModal, setrenderModal] = useState(false);
    const [state, setState] = React.useState(false);
    const [network, setNetwork] = useState('EVMChains');

    const navigate = useNavigate();
    const handleChange = (event) => {
        setNetwork(event.target.value);
    };

    const theme = useTheme();

    const toggleDrawer = () => {
        setState((prev) => (prev === true ? false : true));
    };
    const { address, isConnected } = useAccount();

    const toggleConnectModal = () => () => {
        setConnectModal(!connectModal);
    };

    const toggleDisConnectModal = () => () => {
        setDisconnectModal(!disconnectModal);
    };

    useEffect(() => {
        if (isConnected) {
            setTimeout(() => {
                setDisconnectModal(false);
                setrenderModal(true);
            }, 4000);
        } else {
            setConnectModal(false);
            setrenderModal(false);
        } 
    }, [isConnected]);

    return (
        <>
            {renderModal ? (
                <DisConnectModal
                    toggleDisConnectModal={toggleDisConnectModal}
                    disconnectModal={disconnectModal}
                />
            ) : (
                <ConnectionModal
                    toggleConnectModal={toggleConnectModal}
                    connectModal={connectModal}
                />
            )}
            <Box
                sx={{
                    background: theme.palette.background.light,
                    color: '#fff',
                    fontSize: '24px',
                    fontWeight: 500,
                    textAlign: 'center',
                    width: '100%',
                }}
            >
                Coming Soon ...
            </Box>
            <Box
                sx={{
                    background: `${theme.palette.background.light}`,
                    pb: 4,
                    pt: 2,
                }}
            >
                <Container>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <img src={logo} alt="" width="55px" />
                        <Hidden mdDown>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                }}
                            >
                                <Select
                                    value={network}
                                    defaultValue={'EVMChains'}
                                    onChange={handleChange}
                                    sx={{
                                        '&.MuiOutlinedInput-root': {
                                            backgroundColor: '#353547',
                                            height: '35px',
                                        },
                                    }}
                                >
                                    {subMenus.map(({ name, to, logo }) => {
                                        return (
                                            <MenuItem key={to + name} value={name}>
                                                <MuiLink
                                                    href={to}
                                                    color="#fff"
                                                    sx={{
                                                        textDecoration: 'none',
                                                        display: 'flex',
                                                        gap: 1,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        width="20px"
                                                        height="20px"
                                                    />{' '}
                                                    <Typography> {name}</Typography>
                                                </MuiLink>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {isConnected ? (
                                    <Button variant="gradient" onClick={toggleDisConnectModal()}>
                                        {address.slice(0, 6) + '...' + address.slice(-4)}
                                    </Button>
                                ) : (
                                    <Button variant="gradient" onClick={toggleConnectModal()}>
                                        Connect
                                    </Button>
                                )}
                            </Box>
                        </Hidden>

                        <Box
                            sx={{
                                flexDirection: 'row',
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                            }}
                        >
                            <IconButton onClick={() => toggleMode()}>
                                {mode === 'dark' ? <DarkModeIcon /> : <LightModeIcon />}
                            </IconButton>
                            <IconButton onClick={() => setOpenProfile(!openProfile)}>
                                <User />
                            </IconButton>
                            <Hidden mdUp>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                    }}
                                >
                                    <IconButton onClick={() => toggleDrawer()}>
                                        <MenuIcon
                                            style={{
                                                fontSize: '28px',
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                                <Drawer anchor="right" open={state} onClose={() => toggleDrawer()}>
                                    <Box
                                        sx={{
                                            background: `${theme.palette.background.medium}`,
                                            width: 300,
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 5,
                                            pt: 8,
                                            px: 2,
                                        }}
                                        role="presentation"
                                    >
                                        <img src={logo} alt="" width="55px" />
                                        <Select
                                            value={network}
                                            defaultValue={'EVMChains'}
                                            onChange={handleChange}
                                            sx={{
                                                '&.MuiOutlinedInput-root': {
                                                    backgroundColor: '#353547',
                                                    height: '35px',
                                                },
                                            }}
                                        >
                                            {subMenus.map(({ name, to, logo }) => {
                                                return (
                                                    <MenuItem key={to + name} value={name}>
                                                        <MuiLink
                                                            href={to}
                                                            color="#fff"
                                                            sx={{
                                                                textDecoration: 'none',
                                                                display: 'flex',
                                                                gap: 1,
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <img
                                                                src={logo}
                                                                alt=""
                                                                width="20px"
                                                                height="20px"
                                                            />{' '}
                                                            <Typography> {name}</Typography>
                                                        </MuiLink>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {isConnected ? (
                                            <Button
                                                variant="gradient"
                                                fullWidth
                                                sx={{
                                                    fontSize: '12px',
                                                }}
                                                onClick={toggleDisConnectModal()}
                                            >
                                                {address.slice(0, 6) + '...' + address.slice(-4)}
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="gradient"
                                                fullWidth
                                                sx={{
                                                    fontSize: '12px',
                                                }}
                                                onClick={toggleConnectModal()}
                                            >
                                                Connect
                                            </Button>
                                        )}
                                    </Box>
                                </Drawer>
                            </Hidden>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Header;

import React from 'react';

import { WagmiConfig, createClient, configureChains } from 'wagmi';

import {
    bsc,
    mainnet,
    polygon,
    telos,
    moonriver,
    fantom,
    avalanche,
    aurora,
    arbitrum,
} from 'wagmi/chains';

import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import App from './App';
import ContextAPI from './utils/ContextAPI';

const { chains, provider, webSocketProvider } = configureChains(
    [mainnet, bsc, polygon, telos, moonriver, fantom, avalanche, aurora, arbitrum],
    [alchemyProvider({ apiKey: '0fC4kOBJp5XgPZVIgS8dO4GbpyyMf16T' }), publicProvider()],
);

const client = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new WalletConnectConnector({
            chains,
            options: {
                projectId: 'adc44ed1cb9a9bdf0f9c5f85279b7c89',
            },
        }),
    ],
    provider,
    webSocketProvider,
});

function WagmiUtils() {
    return (
        <WagmiConfig client={client}>
            <ContextAPI>
                <App />
            </ContextAPI>
        </WagmiConfig>
    );
}

export default WagmiUtils;

import React, { useContext, useState } from 'react';

import {
    Box,
    Button,
    Container,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import robotImg from '../assets/images/sentry.png';
import dragonImg from '../assets/images/serpent.png';

import blackdragon from '../assets/images/serpentblack.jpg';
import blkackRobot from '../assets/images/sentryblack.jpg';
import whiteDragon from '../assets/images/serpentwhite.jpg';
import whiteRobot from '../assets/images/sentrywhite.jpg';

import { makeStyles } from '@mui/styles';
import { DataContext } from '../utils/ContextAPI';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles({
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent', // Remove background color on hover
        },
    },
});

const OnBoard = () => {
    const { setCustomBackground } = useContext(DataContext);
    const steps = ['Faction', 'Theme', 'Set Name'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [radio, setradio] = useState('');
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [isValid, setIsValid] = useState(false);
    const theme = useTheme();

    const handleUsernameChange = (event) => {
        const newUsername = event.target.value;
        setUsername(newUsername);

        setIsValid(newUsername.length >= 5 && newUsername.length <= 15);
    };

    const themeImg = {
        height: '60px',
        borderTopRightRadius: '24px',
        borderBottomRightRadius: '24px',
        position: 'relative',
        top: '3px',
        right: '3px',
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        // if (activeStep == steps.length - 1) {
        //     navigate('/');
        // }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const CustomRadio = ({ label, imageSrc, value, selectedValue, onChange }) => {
        const classes = useStyles();
        const radioStyle = {
            border:
                selectedValue === value
                    ? `2px solid ${theme.palette.background.light}`
                    : '2px solid transparent',
            borderRadius: '5px',
            padding: '4px',
            width: '200px',
        };

        const handleChange = () => {
            onChange(value);
        };

        return (
            <FormControlLabel
                control={
                    <Radio
                        checked={selectedValue === value}
                        onChange={handleChange}
                        icon={<img src={imageSrc} style={radioStyle} alt={label} />}
                        checkedIcon={<img src={imageSrc} style={radioStyle} alt={label} />}
                        name={label}
                        value={value}
                        classes={{
                            root: classes.radioRoot, // Apply custom styles to the radio button root element
                        }}
                    />
                }
                label={label}
            />
        );
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleChangetheme = (value) => {
        setSelectedValue(value);
    };

    const handleReset = () => {
        localStorage.setItem('bg', selectedValue.replace(/\s/g, '').toLowerCase());
        setCustomBackground(selectedValue.replace(/\s/g, '').toLowerCase());
        navigate('/');
    };

    return (
        <Box>
            <Container maxWidth="lg">
                <Grid
                    alignItems="center"
                    container
                    sx={{
                        py: 3,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '50vh',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <React.Fragment>
                            <Typography
                                sx={{
                                    mt: 2,
                                    mb: 1,
                                    width: '100%',
                                }}
                            >
                                <FormControl
                                    variant="standard"
                                    sx={{
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <Typography sx={{ fontSize: '25px', fontFamily: 'Squada One' }}>
                                        Choose Swap Expreience
                                    </Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-error-radios"
                                        name="ether"
                                        value={radio}
                                        onChange={(e) => {
                                            // // setradio(e.target.value);
                                            // alert('You have selected ' + e.target.value);
                                            if (e.target.value === '1') {
                                                localStorage.setItem('bg', null);
                                                setCustomBackground(null);
                                                navigate('/');
                                            } else {
                                                navigate('/starter');
                                            }
                                        }}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: { sm: '50%', xs: '100%' },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                pr: { sm: 0.5, xs: 0.8 },
                                                border: '2px #C0C0C0 solid',
                                                borderRadius: '27px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: { sm: '90%', xs: '85%', height: '60px' },
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="1"
                                                    control={<Radio />}
                                                    label="Traditional Swap Experience "
                                                    sx={{
                                                        position: 'relative',
                                                        top: '13px',
                                                        left: '19px',
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: { sm: '50%', xs: '100%' },
                                                display: 'flex',
                                                flexDirection: 'row',
                                                border: '2px #C0C0C0 solid',
                                                pr: { sm: 0.5, xs: 0.8 },
                                                borderRadius: '27px',
                                                marginTop: '20px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: { sm: '90%', xs: '85%', height: '60px' },
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="2"
                                                    control={<Radio />}
                                                    label="Earn Rewards"
                                                    sx={{
                                                        position: 'relative',
                                                        top: '13px',
                                                        left: '19px',
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </RadioGroup>
                                </FormControl>
                            </Typography>
                        </React.Fragment>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default OnBoard;

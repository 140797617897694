import React, { useContext, useEffect, useMemo } from 'react';
import { responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider, Backdrop } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';

import Header from './Components/Header';
import { createCustomTheme } from './utils/createCustomTheme';
import Swap from './Pages/Swap/Home';
import { DataContext } from './utils/ContextAPI';
import TokenList from './Components/Swap/TokenList';
import { QueryClientProvider } from '@tanstack/react-query';
import { networks } from './utils/networkList';
import { Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Starter from './Pages/Starter';
import OnBoard from './Pages/OnBoard';

const App = () => {
    const { loader, setLoader, mode, toggleMode, queryClient, selectedToken } =
        useContext(DataContext);
    const { switchNetwork, error, isLoading } = useSwitchNetwork();
    const { isConnected } = useAccount();
    const { chain } = useNetwork();

    const themeClient = useMemo(() => {
        let theme = createCustomTheme(mode);
        theme = responsiveFontSizes(theme);
        return theme;
    }, [mode]);

    useEffect(() => {
        if (isConnected) {
            let connected = networks.find((obj) => obj.name === selectedToken.token1.network);
            if (connected?.chainId !== chain?.id) switchNetwork?.(connected?.chainId);
        }
        setLoader(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chain, selectedToken, error, isLoading]);

    useEffect(() => {
        setLoader(isLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <>
            <ThemeProvider theme={themeClient}>
                <CssBaseline enableColorScheme />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    limit={3}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={mode}
                />
                <QueryClientProvider client={queryClient}>
                    <TokenList />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loader}
                    >
                        <Bars
                            height="80"
                            width="80"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </Backdrop>

                    <Header mode={mode} toggleMode={toggleMode} />
                    <Routes>
                        <Route exact path="/OnBoard" element={<OnBoard />} />
                        <Route exact path="/starter" element={<Starter />} />
                        <Route exact path="/" element={<Swap />} />
                    </Routes>
                    <Footer />
                </QueryClientProvider>
            </ThemeProvider>
        </>
    );
};

export default App;

import { useEffect } from "react"; 

 export default function JupiterWidget(){ 
    useEffect(() => {
        window.Jupiter.init({
            displayMode: "widget",
            integratedTargetId: "integrated-terminal",
            endpoint: "https://api.mainnet-beta.solana.com",
            formProps: {
              fixedOutputMint: true,
              initialOutputMint: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
            },
          }); 
    },[]);
    return <div id="integrated-terminal"></div>
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Check from '@mui/icons-material/Check';
import { DataContext } from '../utils/ContextAPI'; 
export default function ChartsDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isChart, isJupiter, setIsJupiter, setIsChart } = React.useContext(DataContext);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (info) => {  
    switch (info) {
        case 'chart':
        if(isChart){
            localStorage.removeItem('chart');
            setIsChart(false);
        }else {
            localStorage.setItem('chart','tradingView');
            setIsChart(true);
        }
            break;
        case 'jupiter':
         if(isJupiter){
            localStorage.removeItem('jupiter');
            document.getElementById('jupiter-terminal').remove();
            setIsJupiter(false);
        } else{
            localStorage.setItem('jupiter',true);
            setIsJupiter(true);
        }
            break;
        case 'noChart':
            localStorage.removeItem('chart');
            localStorage.removeItem('jupiter');
            document.getElementById('jupiter-terminal').remove();
            setIsChart(false);
            setIsJupiter(false);
            break;
    
        default:
            break;
    }
    setAnchorEl(null); 
};

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Charts Settings
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() => handleClose('noChart')}> { !isChart && !isJupiter ? <Check/> : null } No Chart</MenuItem>
        <MenuItem onClick={() => handleClose('chart')}>  { isChart  ? <Check/> : null } Trading View</MenuItem> 
        <MenuItem onClick={() => handleClose('jupiter')}>  {  isJupiter ? <Check/> : null } Jupiter Widget</MenuItem> 
      </Menu>
    </div>
  );
}
import React, { useContext, useState } from 'react';

import {
    Box,
    Button,
    Container,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import robotImg from '../assets/images/sentry.png';
import dragonImg from '../assets/images/serpent.png';

import blackdragon from '../assets/images/serpentblack.jpg';
import blkackRobot from '../assets/images/sentryblack.jpg';
import whiteDragon from '../assets/images/serpentwhite.jpg';
import whiteRobot from '../assets/images/sentrywhite.jpg';

import { makeStyles } from '@mui/styles';
import { DataContext } from '../utils/ContextAPI';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import AdvancedChart from '../Components/Charts/AdvancedChart';

const useStyles = makeStyles({
    radioRoot: {
        '&:hover': {
            backgroundColor: 'transparent', // Remove background color on hover
        },
    },
});

const Starter = () => {
    const { setCustomBackground, username, setUsername } = useContext(DataContext);
    const steps = ['Faction', 'Theme', 'Charts', 'Set Name'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [radio, setradio] = useState('');
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState('');
    const [ userNameError , setUserNameError] = useState('');
    const theme = useTheme();
    
    const handleUsernameChange = (event) => {
        const newUsername = event.target.value;
        setUsername(newUsername); 
        setIsValid(newUsername.length >= 5 && newUsername.length <= 15 ? true : false);
    };

    const themeImg = {
        height: '60px',
        borderTopRightRadius: '24px',
        borderBottomRightRadius: '24px',
        position: 'relative',
        top: '3px',
        right: '3px',
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep == steps.length - 1) {
            navigate('/');
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const CustomRadio = ({ label, imageSrc, value, selectedValue, onChange }) => {
        const classes = useStyles();
        const radioStyle = {
            border:
                selectedValue === value
                    ? `2px solid ${theme.palette.background.light}`
                    : '2px solid transparent',
            borderRadius: '5px',
            padding: '4px',
            width: '200px',
        };

        const handleChange = () => {
            onChange(value);
        };

        return (
            <FormControlLabel
                control={
                    <Radio
                        checked={selectedValue === value}
                        onChange={handleChange}
                        icon={<img src={imageSrc} style={radioStyle} alt={label} />}
                        checkedIcon={<img src={imageSrc} style={radioStyle} alt={label} />}
                        name={label}
                        value={value}
                        classes={{
                            root: classes.radioRoot, // Apply custom styles to the radio button root element
                        }}
                    />
                }
                label={label}
            />
        );
    };

    const [selectedValue, setSelectedValue] = useState('');
    const [selectedChart,setSelectedChart] = useState({
        tradingView: false,
        jupiter:false
    });

    const handleChangetheme = (value) => {
        setSelectedValue(value);
    };

    const handleChartSelection = (key = 'chart',name = 'tradingView') => {
        localStorage.setItem(key, name);
        setSelectedChart({
            ...selectedChart,
            [name] : true
        });
    }

    const handleReset = () => {
        localStorage.setItem('bg', selectedValue.replace(/\s/g, '').toLowerCase());
        localStorage.setItem('name', username);
        setCustomBackground(selectedValue.replace(/\s/g, '').toLowerCase());
        navigate('/');
    };

    return (
        <Box>
            <Container maxWidth="lg">
                <Box
                    sx={{
                        py: 4,
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Squada One',
                            fontSize: '60px',
                            color: theme.palette.primary.silver,
                        }}
                    >
                        {activeStep === 0
                            ? 'Choose Faction'
                            : activeStep === 1
                            ? 'Swap Them'
                            : activeStep === 2
                            ? 'Select Chart'
                            : 'Set Name'}
                    </Typography>
                </Box>
                <Grid
                    alignItems="center"
                    container
                    sx={{
                        py: 3,
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption"></Typography>
                                    );
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === steps?.length ? (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                {radio && radio} {selectedValue && selectedValue}{' '}
                                {username && username}
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Continue</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    {activeStep + 1 === 1 ? (
                                        <FormControl variant="standard" sx={{ width: '100%' }}>
                                            <Typography
                                                sx={{ fontSize: '25px', fontFamily: 'Squada One' }}
                                            >
                                                Decide Faction
                                            </Typography>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-error-radios"
                                                name="ether"
                                                value={radio}
                                                onChange={(e) => {
                                                    setradio(e.target.value);
                                                }}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: { sm: '50%', xs: '100%' },
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        pr: { sm: 0.5, xs: 0.8 },
                                                        border: '2px #C0C0C0 solid',
                                                        borderRadius: '27px',
                                                    }}
                                                >
                                                    <Box sx={{ width: { sm: '90%', xs: '85%' } }}>
                                                        <FormControlLabel
                                                            value="Serpent Faction"
                                                            control={<Radio />}
                                                            label="Serpent  Faction"
                                                            sx={{
                                                                position: 'relative',
                                                                top: '13px',
                                                                left: '19px',
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box sx={{ width: '10%' }}>
                                                        <img
                                                            src={dragonImg}
                                                            style={themeImg}
                                                            alt="robotImage"
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: { sm: '50%', xs: '100%' },
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        border: '2px #C0C0C0 solid',
                                                        pr: { sm: 0.5, xs: 0.8 },
                                                        borderRadius: '27px',
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    <Box sx={{ width: { sm: '90%', xs: '85%' } }}>
                                                        <FormControlLabel
                                                            value="Sentry Faction"
                                                            control={<Radio />}
                                                            label="Sentry Faction"
                                                            sx={{
                                                                position: 'relative',
                                                                top: '13px',
                                                                left: '19px',
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box sx={{ width: '10%' }}>
                                                        <img
                                                            src={robotImg}
                                                            style={themeImg}
                                                            alt="dragonImg"
                                                        />
                                                    </Box>
                                                </Box>
                                            </RadioGroup>
                                        </FormControl>
                                    ) : activeStep + 1 === 2 ? (
                                        <div>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '25px',
                                                        fontFamily: 'Squada One',
                                                    }}
                                                >
                                                    Solar Themes
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <div>
                                                        <CustomRadio
                                                            label="Sentry Black"
                                                            imageSrc={blkackRobot}
                                                            value="Sentry Black"
                                                            selectedValue={selectedValue}
                                                            onChange={handleChangetheme}
                                                        />
                                                        <CustomRadio
                                                            label="Sentry White"
                                                            imageSrc={whiteRobot}
                                                            value="Sentry White"
                                                            selectedValue={selectedValue}
                                                            onChange={handleChangetheme}
                                                        />
                                                        <CustomRadio
                                                            label="Serpent Black"
                                                            imageSrc={blackdragon}
                                                            value="Serpent Black"
                                                            selectedValue={selectedValue}
                                                            onChange={handleChangetheme}
                                                        />
                                                        <CustomRadio
                                                            label="Serpent White"
                                                            imageSrc={whiteDragon}
                                                            value="Serpent White"
                                                            selectedValue={selectedValue}
                                                            onChange={handleChangetheme}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontSize: '16px',
                                                                fontFamily: 'Squada One',
                                                            }}
                                                        >
                                                            More NFT Collections Coming Soon
                                                        </Typography>
                                                        <div>
                                                            Selected Value:{' '}
                                                            {selectedValue && selectedValue}
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </div>
                                    ) : activeStep + 1 === 3 ? (
                                        <div>
                                            <Typography
                                                sx={{ fontSize: '25px', fontFamily: 'Squada One' }}
                                            >
                                                Widgets
                                            </Typography>
                                            <FormControl
                                                variant="standard"
                                                sx={{
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-error-radios"
                                                    name="ether"
                                                    value={radio}
                                                    onChange={(e) => {}}
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        width: '100%',
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: { sm: '49%', xs: '100%' },
                                                            marginRight: { sm: '2%', xs: 0 },
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: { sm: '100%', xs: '100%' },
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                pr: { sm: 0.5, xs: 0.8 },
                                                                border: '2px #C0C0C0 solid',
                                                                borderRadius: '27px',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: {
                                                                        sm: '90%',
                                                                        xs: '85%',
                                                                        height: '60px',
                                                                    },
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value="tradingView"
                                                                    control={<Radio />}
                                                                    label="Trading View"
                                                                    sx={{
                                                                        position: 'relative',
                                                                        top: '13px',
                                                                        left: '19px',
                                                                    }} 
                                                                    checked={selectedChart.tradingView}
                                                                    onClick={() => handleChartSelection() }
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width: { sm: '100%', xs: '100%' },
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                border: '2px #C0C0C0 solid',
                                                                pr: { sm: 0.5, xs: 0.8 },
                                                                borderRadius: '27px',
                                                                marginTop: '20px',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: {
                                                                        sm: '90%',
                                                                        xs: '85%',
                                                                        height: '60px',
                                                                    },
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value="2"
                                                                    control={<Radio />}
                                                                    label="Jupiter Widget"
                                                                    sx={{
                                                                        position: 'relative',
                                                                        top: '13px',
                                                                        left: '19px',
                                                                    }}
                                                                    checked={selectedChart.jupiter}
                                                                    onClick={() => handleChartSelection(true,'jupiter') }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: { sm: '49%', xs: '100%' },
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: { sm: '100%', xs: '100%' },
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                border: '2px #C0C0C0 solid',
                                                                pr: { sm: 0.5, xs: 0.8 },
                                                                borderRadius: '27px',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: {
                                                                        sm: '90%',
                                                                        xs: '85%',
                                                                        height: '60px',
                                                                    },
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value="2"
                                                                    control={<Radio />}
                                                                    label="Defi Llama"
                                                                    sx={{
                                                                        position: 'relative',
                                                                        top: '13px',
                                                                        left: '19px',
                                                                    }}
                                                                    disabled
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                width: { sm: '100%', xs: '100%' },
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                border: '2px #C0C0C0 solid',
                                                                pr: { sm: 0.5, xs: 0.8 },
                                                                borderRadius: '27px',
                                                                marginTop: '20px',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width: {
                                                                        sm: '90%',
                                                                        xs: '85%',
                                                                        height: '60px',
                                                                    },
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value="2"
                                                                    control={<Radio />}
                                                                    label="Fireye
                                                                "
                                                                    sx={{
                                                                        position: 'relative',
                                                                        top: '13px',
                                                                        left: '19px',
                                                                    }}
                                                                    disabled
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    ) : activeStep + 1 === 4 ? (
                                        <div>
                                            <Typography
                                                sx={{ fontSize: '25px', fontFamily: 'Squada One' }}
                                            >
                                                Set Your Name
                                            </Typography>
                                            <Typography mt={2} fontSize="14px">
                                                Your name must be at least and atmost 15 standard
                                                letters and numbers long.
                                            </Typography>

                                            <Box sx={{ marginTop: '20px', width: '50%' }}>
                                            <TextField
                                            label="Username"
                                            variant="outlined"
                                            fullWidth
                                            value={username}
                                            onChange={handleUsernameChange}
                                            error={username && !isValid}
                                            helperText={username && !isValid
                                            ? 'Username must be between 5 and 15 characters' : ''}
                                            /> 
                                            {username && (isValid ? (
                                            <IconButton color="success">
                                                <CheckCircleIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton color="error">
                                                <ErrorOutlineIcon />
                                            </IconButton>
                                        ))}
                                            </Box>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />

                                    <Button onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
};

export default Starter;

import * as React from 'react'; 
import { Box, Typography, Stack, Avatar } from '@mui/material';
import { DataContext } from '../utils/ContextAPI';
import { useAccount, useBalance } from 'wagmi';
import ChartsDropdown from './ChartsDropdown';
const ProfileDrawer = () => { 
    const {  address , isConnected   } = useAccount(); 
    const [balance, setBalance] = React.useState('0.00 $');
    const usdtAdress = '0xdAC17F958D2ee523a2206206994597C13D831ec7';
    const {data} = useBalance({ address, token: usdtAdress }); 
    React.useEffect(() => {   
        if(isConnected){ 
             setBalance(data?.formatted + ' ' +  data?.symbol); 
        }
    },[data,isConnected]); 
    return (
        <Box sx={{ maxWidth: '500px', minWidth: '300px' }}>
            <Stack
                sx={{
                    padding: '20px',
                    alignItems: 'center',
                }}
                direction="row"
                spacing={2}
            >
                <Avatar
                    alt={localStorage.getItem('name') || 'User'}
                    src="https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Fuser-icon-png&psig=AOvVaw0A6EvlWvFQGGrO_jBA9tYQ&ust=1716641555166000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCLC6n6eqpoYDFQAAAAAdAAAAABAE"
                />
                <Box>
                    <Typography sx={{ fontSize: 20, fontWeight: '600' }}>
                        {localStorage.getItem('name') || 'New User'}
                    </Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: '300' }}>
                        {address
                            ? address?.slice(0, 5) +
                              '....' +
                              address?.slice(address.length - 4, address?.length)
                            : '0x0000...0000'}
                    </Typography>
                </Box>
            <ChartsDropdown />
            </Stack>
            <Typography
                marginLeft={'20px'}
                sx={{
                    fontSize: 20,
                    fontWeight: '600',
                }}
            >
                { balance }{' '}
            </Typography>
        </Box>
    );
};

export default ProfileDrawer;

import { Loop, Token } from '@mui/icons-material';
 import {
    Box,
    Button,
    IconButton,
    InputBase,
    Stack,
    Typography,
    useTheme,
    Divider,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAccount, useBalance } from 'wagmi';
import { SDK, OpenOceanTrade, AlgebraTrade, LifiTrade, Injector } from 'rubic-sdk';

import { DataContext } from '../../utils/ContextAPI';
import SlippingTolrance from '../../Components/Swap/Slippage';
import { networks } from '../../utils/networkList';

function findObjectById(array, idToFind) {
    let obj = array.find((obj) => obj.name === idToFind);
    return obj.img;
}

const SwapModal = ({openChart}) => {
    const {
        toggleList,
        setCurrentTokenSelection,
        switchTokens,
        selectedToken,
        swapDeposit1,
        swapDeposit2,
        setSwapDeposit1,
        setSwapDeposit2,
        setLoader,
        config,
    } = useContext(DataContext);
    const { isConnected, address } = useAccount();
    const [openSlippage, setopenSlippage] = useState(false);
    const [perTokenValue, setperTokenValue] = useState();
    const [localLoading, setLocalLoading] = useState(false);
    const theme = useTheme();

    const { data } = useBalance({
        address,
        token:
            selectedToken.token1.address === '0x0000000000000000000000000000000000000000'
                ? null
                : selectedToken.token1.address,
        formatUnits: selectedToken.token1.decimals,
    });
    const [trade, setTrade] = useState(null);
    const [needApprove, setApprove] = useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkApproval = async () => {
        const isApproved = (await trade?.needApprove()) || false;
        setApprove(isApproved);
    };

    useEffect(() => {
        if (trade) checkApproval();
    }, [trade, checkApproval]);

    const handleSwap = async (trade, needApprove, approveHandle) => {
        try {
            setLocalLoading(true);
            // swap function
            if (!trade) return;

            const blockchainAdapter = Injector.web3PublicService.getWeb3Public(
                trade.from.blockchain,
            );
            const gasPrice = await blockchainAdapter.getGasPrice();
            // console.log('gas price', gasPrice);
            if (
                !needApprove ||
                trade?.from.address === '0x0000000000000000000000000000000000000000'
            ) {
                const result = await trade?.swap({
                    onConfirm: (hash) => {
                        alert(`Swap transaction ${hash} was sent.`);
                    },
                    gasPrice,
                });
                // console.log(result, 'result');
                toast.success('Swap Successful');
            } else {
                const tx = {
                    onTransactionHash: () => {
                        toast.success('Approve transaction was sent.');
                    },
                    gasPrice,
                };

                const result = await trade?.approve(tx);
                approveHandle();
                // console.log(result);
            }
            setLocalLoading(false);
        } catch (error) {
            console.log(error);
            setLocalLoading(false);
            toast.error(error.message);
        }
    };

    const getExpectedValue = async (amount) => {
        try {
            setLocalLoading(true);
            let sdk = await SDK.createSDK(config);

            let blockchain1 = networks.find((obj) => obj.name === selectedToken.token1.network);
            let blockchain2 = networks.find((obj) => obj.name === selectedToken.token2.network);
            const fromToken = {
                blockchain: blockchain1.chain,
                address: selectedToken.token1.address,
            };
            const toTokenAddress = {
                blockchain: blockchain2.chain,
                address: selectedToken.token2.address,
            };
            // calculate trades
            if (selectedToken.token1.network === selectedToken.token2.network) {
                const timeNow = new Date().getTime();
                const wrappedTrades = await sdk.onChainManager.calculateTrade(
                    fromToken,
                    String(amount),
                    toTokenAddress,
                );
                // console.log(wrappedTrades, 'wrappedTrades');
                const timeAfter = new Date().getTime();
                console.log('Time taken for onChainManager', timeAfter - timeNow);
                const bestTrade = wrappedTrades.filter(
                    (el) =>
                        !(el instanceof LifiTrade) &&
                        !(el instanceof AlgebraTrade) &&
                        !(el instanceof OpenOceanTrade) &&
                        !('error' in el),
                );

                let onChainTrade = bestTrade[0].trade;
                console.log(onChainTrade, 'onChainTrade');

                setSwapDeposit2(onChainTrade?.toTokenAmountMin.tokenAmount?.toFixed(4));
                setTrade(onChainTrade);
            } else {
                const timeNow = new Date().getTime();
                const wrappedTrades = await sdk.crossChainManager.calculateTrade(
                    fromToken,
                    String(amount),
                    toTokenAddress,
                );
                const timeAfter = new Date().getTime();
                // console.log('Time taken for crossChainManager', timeAfter - timeNow);
                const bestTrade = wrappedTrades[0].trade;
                const fee = bestTrade.feeInfo.rubicProxy.fixedFee.amount.toFixed(4);
                const priceOfToken = bestTrade.feeInfo.rubicProxy.fixedFee.token.price.toFixed(4);
                console.log(fee, priceOfToken, 'bestTrade');

                setSwapDeposit2(bestTrade?.toTokenAmountMin.toFixed(4));
                setTrade(bestTrade);
            }

            setLocalLoading(false);
        } catch (error) {
            setLocalLoading(false);
            toast.error(error.message);
        }
    };

    const getExpectedPerValue = async (amount) => {
        try {
            setLocalLoading(true);

            let sdk = await SDK.createSDK(config);
            let blockchain1 = networks.find((obj) => obj.name === selectedToken.token1.network);
            let blockchain2 = networks.find((obj) => obj.name === selectedToken.token2.network);
            const fromToken = {
                blockchain: blockchain1.chain,
                address: selectedToken.token1.address,
            };
            const toTokenAddress = {
                blockchain: blockchain2.chain,
                address: selectedToken.token2.address,
            };
            // calculate trades
            if (selectedToken.token1.network === selectedToken.token2.network) {
                const wrappedTrades = await sdk.onChainManager.calculateTrade(
                    fromToken,
                    String(amount),
                    toTokenAddress,
                );

                const bestTrade = wrappedTrades.filter(
                    (el) =>
                        !(el instanceof LifiTrade) &&
                        !(el instanceof AlgebraTrade) &&
                        !(el instanceof OpenOceanTrade) &&
                        !('error' in el),
                );
                const onChainTrade = bestTrade[0].trade;

                setperTokenValue(onChainTrade?.toTokenAmountMin.tokenAmount?.toFixed(4));
            } else {
                const wrappedTrades = await sdk.crossChainManager.calculateTrade(
                    fromToken,
                    String(amount),
                    toTokenAddress,
                    {
                        slippageTolerance: 0.01,
                    },
                );
                const bestTrade = wrappedTrades[0].trade;
                setperTokenValue(bestTrade?.toTokenAmountMin.toFixed(4));
            }

            setLocalLoading(false);
        } catch (error) {
            setLocalLoading(false);
            toast.error(error.message);
        }
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            if (swapDeposit1) getExpectedValue(swapDeposit1);
        }, 1000);

        return () => clearTimeout(getData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swapDeposit1, selectedToken]);

    useEffect(() => {
        setperTokenValue(null);
        setSwapDeposit2(null);
        const getData = setTimeout(() => {
            if (isConnected) getExpectedPerValue(1);
        }, 1000);

        return () => clearTimeout(getData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, selectedToken]);

    return (
        <>
            <SlippingTolrance openSlippage={openSlippage} setopenSlippage={setopenSlippage} />
            <Stack direction="column" alignItems="center" justifyContent="center" my={5}>
                <Box
                    sx={{
                        width: '100%',
                        p: 5,
                        borderRadius: '15px',
                        bgcolor:
                            theme.palette.mode === 'dark'
                                ? 'rgba(0, 0, 0, 0.9)'
                                : 'rgba(255, 255, 255, 0.8)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                        boxShadow: `0px 0px 8px 0px ${theme.palette.background.shadow}`,
                        maxWidth: '550px',
                    }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack direction="column" alignItems="start">
                            <Typography
                                sx={{
                                    fontSize: '48px',
                                    fontFamily: 'Squada One',
                                }}
                            >
                                Swap
                            </Typography>
                            <Typography sx={{ fontSize: { xs: '10px', sm: '14px' } }}>
                                Trade tokens in an instant
                            </Typography>
                        </Stack>
                        {/* <IconButton onClick={openChart}>
                            <BarChart />
                        </IconButton> */}
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Button
                            onClick={() => {
                                setCurrentTokenSelection('token1');
                                toggleList();
                            }}
                            sx={{
                                gap: 1,
                                textTransform: 'none',
                                py: 1,
                                width: '170px',
                                color: 'text.primary',
                                borderRadius: '15px',
                                border: `1px solid ${theme.palette.background.borderLight}`,
                                '&:hover': {
                                    bgcolor: 'background.hard',
                                },
                            }}
                        >
                            <img
                                src={findObjectById(networks, selectedToken?.token1?.network)}
                                alt="coin"
                                width="30px"
                                height="30px"
                            />
                            <Divider orientation="vertical" flexItem />
                            {selectedToken.token1.image ? (
                                <img
                                    src={selectedToken.token1.image}
                                    alt="coin"
                                    width="30px"
                                    height="30px"
                                />
                            ) : (
                                <Token />
                            )}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                }}
                            >
                                {selectedToken.token1.symbol}
                            </Typography>
                        </Button>
                        <Stack
                            direction="row"
                            sx={{
                                direction: 'row',
                                alignItems: 'center',
                                gap: 2,
                                width: '100%',
                                bgcolor: 'background.medium',
                                borderRadius: '15px',
                                border: `1px solid ${theme.palette.background.borderLight}`,
                                p: 2,
                            }}
                        >
                            <InputBase
                                type="number"
                                value={swapDeposit1}
                                onChange={(e) => setSwapDeposit1(e.target.value)}
                                sx={{ width: '100%', fontSize: '18px' }}
                                placeholder="Enter Amount"
                            />
                        </Stack>
                        {data !== undefined ? (
                            <Typography sx={{ textAlign: 'end' }}>
                                Balance: {Number(data?.formatted).toFixed(4)} {data?.symbol}
                            </Typography>
                        ) : null}
                    </Box>
                    <IconButton
                        onClick={() => switchTokens()}
                        sx={{
                            color: 'text.primary',
                            background: `${theme.palette.background.color1}`,
                            p: 1,
                        }}
                    >
                        <Loop fontSize="35px" />
                    </IconButton>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Button
                            onClick={() => {
                                setCurrentTokenSelection('token2');
                                toggleList();
                            }}
                            sx={{
                                gap: 1,
                                textTransform: 'none',
                                width: '170px',
                                py: 1,
                                color: 'text.primary',
                                borderRadius: '15px',
                                border: `1px solid ${theme.palette.background.borderLight}`,
                                '&:hover': {
                                    bgcolor: 'background.hard',
                                },
                            }}
                        >
                            <img
                                src={findObjectById(networks, selectedToken?.token2?.network)}
                                alt="coin"
                                width="30px"
                                height="30px"
                            />
                            <Divider orientation="vertical" flexItem />
                            {selectedToken.token2.image ? (
                                <img
                                    src={selectedToken.token2.image}
                                    alt="coin"
                                    width="30px"
                                    height="30px"
                                />
                            ) : (
                                <Token />
                            )}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: '500',
                                }}
                            >
                                {selectedToken.token2.symbol}
                            </Typography>
                        </Button>
                        <Stack
                            direction="row"
                            sx={{
                                direction: 'row',
                                alignItems: 'center',
                                gap: 2,
                                width: '100%',
                                bgcolor: 'background.medium',
                                borderRadius: '15px',
                                border: `1px solid ${theme.palette.background.borderLight}`,
                                p: 2,
                            }}
                        >
                            <InputBase
                                value={swapDeposit2}
                                readOnly
                                sx={{
                                    width: '100%',
                                    fontSize: '18px',
                                    color:
                                        swapDeposit1 === null ||
                                        swapDeposit1 === '' ||
                                        swapDeposit2 === null ||
                                        swapDeposit2 === '' ||
                                        !swapDeposit2 ||
                                        localLoading ||
                                        !isConnected
                                            ? 'text.disabled'
                                            : 'text.primary',
                                }}
                                placeholder="Enter Amount"
                            />
                        </Stack>
                    </Box>

                    <Stack
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            sx={{
                                color:
                                    swapDeposit1 === null ||
                                    swapDeposit1 === '' ||
                                    swapDeposit2 === null ||
                                    swapDeposit2 === '' ||
                                    !swapDeposit2 ||
                                    localLoading ||
                                    !isConnected
                                        ? 'text.disabled'
                                        : 'text.primary',
                            }}
                        >
                            Price
                        </Typography>
                        <Typography
                            sx={{
                                color:
                                    swapDeposit1 === null ||
                                    swapDeposit1 === '' ||
                                    swapDeposit2 === null ||
                                    swapDeposit2 === '' ||
                                    !swapDeposit2 ||
                                    localLoading ||
                                    !isConnected
                                        ? 'text.disabled'
                                        : 'text.primary',
                            }}
                        >
                            {perTokenValue} {selectedToken.token2.symbol} Per{' '}
                            {selectedToken.token1.symbol}
                        </Typography>
                    </Stack>

                    <Button
                        variant="gradient"
                        sx={{
                            width: '100%',
                            py: 2,
                        }}
                        onClick={() => handleSwap(trade, needApprove, () => setApprove(false))}
                        disabled={
                            swapDeposit1 === null ||
                            swapDeposit1 === '' ||
                            swapDeposit2 === null ||
                            swapDeposit2 === '' ||
                            !swapDeposit2 ||
                            localLoading ||
                            !isConnected
                        }
                    >
                        {needApprove ? 'Approve' : 'Swap'}
                    </Button>
                </Box>
            </Stack>
        </>
    );
};

export default SwapModal;

import React, { useEffect, useRef, memo, useContext, useState } from 'react';
import { DataContext } from '../../utils/ContextAPI';
import { useMediaQuery } from '@mui/material'; 

function AdvancedChart() {
  const container = useRef();
  const { mode,selectedToken } = useContext(DataContext);

  const matches = useMediaQuery('(min-width:600px)');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => {
      console.log('ere',window.innerWidth);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const currentContainer = container.current;
    // Ensure the container exists
    if (!currentContainer) return;

    // Function to create the TradingView script
    const createScript = async () => {
      try {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
        script.type = 'text/javascript';
        script.async = true;
        script.onload = async () => {
          // Delay initialization of the chart to ensure script has loaded
         await window.TradingView && window.TradingView.initWidget();
        };
        script.onerror = function() {
          console.error('Error loading TradingView script');
        };
        script.innerHTML = `
          { 
            "autosize": true,
            "symbol": "${selectedToken.token1.symbol === "WETH" ? "ETH" : selectedToken.token1.symbol}${selectedToken.token2.symbol}",
            "timezone": "Etc/UTC",
            "theme": "${mode}",
            "style": "1",
            "locale": "en",
            "withdateranges": true,
            "range": "YTD",
            "allow_symbol_change": true,
            "details": true,
            "hotlist": true,
            "calendar": false,
            "support_host": "https://www.tradingview.com"
          }`;

          // Mount the container element
          const tradingViewCopyright = document.createElement('div');
          tradingViewCopyright.className = 'tradingview-widget-copyright';
          tradingViewCopyright.setAttribute('style', 'height: 103%;');  // Set your desired height here
          currentContainer.appendChild(tradingViewCopyright);
          tradingViewCopyright.appendChild(script);
          
      } catch (error) {
        console.error('Error creating TradingView script:', error);
      }
    };

    //error

    window.onerror = () => {
      console.error('Windows Error IN Script');
    }

    // Add the script when the component mounts
    createScript();

    // Cleanup function to remove the script and chart on unmount or before re-run
    return () => {
      if (currentContainer) {
        currentContainer.innerHTML = '';
      }
    };
  }, [mode,selectedToken]);

  return (  
    <div className="tradingview-widget-container" ref={container} style={{ height: "90%", paddingTop: '2rem' ,margin: '2% 0' }}>
      <div className="tradingview-widget-container__widget"></div> 
    </div> 
  );
}

export default memo(AdvancedChart);
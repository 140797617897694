import React, { useContext } from 'react';
import SwapModal from './SwapModal';
import { Container, Grid, Box, Drawer,useMediaQuery } from '@mui/material';

import serpentblack from '../../assets/images/serpentblack.jpg';
import sentryblack from '../../assets/images/sentryblack.jpg';
import serpentwhite from '../../assets/images/serpentwhite.jpg';
import sentrywhite from '../../assets/images/sentrywhite.jpg';
import { DataContext } from '../../utils/ContextAPI';
import ProfileDrawer from '../../Components/ProfileDrawer';
import AdvancedChart from '../../Components/Charts/AdvancedChart'; 
import JupiterWidget from '../../Components/Charts/JupiterWidget';
const obj = {
    serpentblack,
    sentryblack,
    serpentwhite,
    sentrywhite,
};

const Swap = () => {
    const { customBackground, openProfile, setOpenProfile, isChart, isJupiter } = useContext(DataContext);
    const [open, setOpen] = React.useState(false);
    const [openDrawer,setOpenDrawer] = React.useState(false);
    const matches = useMediaQuery('(min-width:600px)');
    const allComp = isChart ? [<AdvancedChart /> , <SwapModal openChart={() => setOpenDrawer(true)}/>] : [<SwapModal/>]
    const Comps = matches ? allComp : allComp.reverse();
    console.log('bblb',isJupiter , isChart);
    return (
        <Box
            sx={{
                background: `url(${obj[customBackground]})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
                
            {/* <React.Fragment key={matches ? 'left' : 'bottom'}>
                <Drawer
                anchor={matches ? 'left' : 'bottom'}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                sx={{ width: '500px' }}
                    > 
                            <AdvancedChart /> 
                 </Drawer>
            </React.Fragment> */}
            <Container>
                <Grid
                container
                spacing={2}
                sx={{  transition: 'all 0.3s' }}>
               {Comps.map((comp) =>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={matches && isChart ? 6 : 12}
                    lg={matches && isChart ? 6 : 12}
                    sx={{ transition: 'all 0.3s' }}>  
                        {comp} 
                    </Grid>)}
                        <React.Fragment key={'right'}>
                            <Drawer
                                anchor={'right'}
                                open={openProfile}
                                onClose={() => setOpenProfile(false)}
                                onOpen={() => setOpenProfile(true)}
                            >
                                <ProfileDrawer />
                            </Drawer>
                        </React.Fragment>
                    </Grid> 
            </Container> 
            {isJupiter ? <JupiterWidget /> : null}
        </Box>
    );
};

export default Swap;
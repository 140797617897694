import { BLOCKCHAIN_NAME } from 'rubic-sdk';

export const networks = [
    {
        name: 'arbitrum',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/arbitrum.svg',
        chain: BLOCKCHAIN_NAME.ARBITRUM,
        chainId: 42161,
    },
    {
        name: 'aurora',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/aurora.svg',
        chain: BLOCKCHAIN_NAME.AURORA,
        chainId: 1313161554,
    },
    {
        name: 'avalanche',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/avalanche.svg',
        chain: BLOCKCHAIN_NAME.AVALANCHE,
        chainId: 43114,
    },
    {
        name: 'binance-smart-chain',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/bnb.svg',
        chain: BLOCKCHAIN_NAME.BINANCE_SMART_CHAIN,
        chainId: 56,
    },
    {
        name: 'ethereum',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/eth-contrast.svg',
        chain: BLOCKCHAIN_NAME.ETHEREUM,
        chainId: 1,
    },
    {
        name: 'fantom',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/fantom.svg',
        chain: BLOCKCHAIN_NAME.FANTOM,
        chainId: 250,
    },
    {
        name: 'moonriver',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/moonriver.svg',
        chain: BLOCKCHAIN_NAME.MOONRIVER,
        chainId: 1285,
    },
    {
        name: 'polygon',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/polygon.svg',
        chain: BLOCKCHAIN_NAME.POLYGON,
        chainId: 137,
    },
    {
        name: 'telos-evm',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/telos.svg',
        chain: BLOCKCHAIN_NAME.TELOS,
        chainId: 40,
    },
    {
        name: 'solana',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/solana.svg',
        chain: BLOCKCHAIN_NAME.SOLANA,
        chainId: 101,
    },
];

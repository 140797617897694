import React, { useContext, useEffect, useState } from 'react';
import { Box, Dialog, Stack, useMediaQuery, InputBase, Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Search, Token } from '@mui/icons-material';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { isAddress } from 'ethers/lib/utils';

import { DataContext } from '../../utils/ContextAPI';
import { networks } from '../../utils/networkList';

export default function TokenList() {
    const { ref, inView } = useInView();
    const {
        currentTokenSelection,
        setSelectedToken,
        selectedToken,
        toggleList,
        openList,
        network,
        setNetwork,
    } = useContext(DataContext);
    const [searchAddress, setSearchAddress] = useState('');
    const [searchSymbol, setSearchSymbol] = useState('');
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery({
        queryKey: ['tokens'],
        queryFn: async ({ pageParam }) => {
            const res = await axios.get(
                `${pageParam}&network=${network.name}&address=${searchAddress}&symbol=${searchSymbol}`,
            );
            return res.data;
        },
        initialPageParam: 'https://tokens.rubic.exchange/api/v1/tokens/?&page=1',
        getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    });

    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
    }, [fetchNextPage, inView]);

    useEffect(() => {
        refetch();
    }, [network, refetch]);
    useEffect(() => {
        refetch();
    }, [searchAddress, searchSymbol, refetch]);

    const tokenChange = (list) => {
        setSelectedToken((prev) => {
            return {
                ...prev,
                [currentTokenSelection]: {
                    name: list.name,
                    symbol: list.symbol,
                    image: list.image,
                    decimals: list.decimals,
                    address: list.address,
                    network: list.blockchainNetwork,
                },
            };
        });
        toggleList();
    };

    const searchFn = (e) => {
        const input = e.target.value;
        if (isAddress(input)) {
            setSearchAddress(input);
            setSearchSymbol('');
        } else {
            setSearchAddress('');
            setSearchSymbol(input);
        }
    };

    // const fetchNetworks = async () => {
    //     const networks = await axios.get('https://tokens.rubic.exchange/api/v1/networks/');
    //     setNetworks(networks.data);
    // };

    // useEffect(() => {
    //     fetchNetworks();
    // }, []);

    return (
        <>
            <Dialog
                fullScreen={smallScreen}
                fullWidth
                open={openList}
                onClose={() => toggleList()}
                sx={{
                    '.MuiDialog-paperScrollPaper': {
                        borderRadius: '10px',
                        background: `${theme.palette.background.light}`,
                    },
                }}
            >
                <Box px={2} py={3}>
                    <Stack direction="column" gap={2}>
                        <Box fontSize="20px" fontWeight={500}>
                            Select a Currency
                        </Box>
                        <Box
                            sx={{
                                background: `${theme.palette.background.hard}`,
                                border: `2px solid ${theme.palette.background.borderLight}`,
                                borderRadius: '5px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                px: 2,
                                py: 0.7,
                            }}
                        >
                            <Search />
                            <InputBase
                                sx={{ width: '100%' }}
                                onChange={(e) => searchFn(e)}
                                value={searchAddress || searchSymbol}
                                placeholder="Search Currency"
                            />
                        </Box>
                        <Stack
                            direction={{ sm: 'row', xs: 'column' }}
                            gap={2}
                            sx={{
                                width: '100%',
                                height: '400px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: { sm: '300px', xs: '100%' },
                                    height: { sm: '100%', xs: '130px' },
                                    overflowX: { sm: 'hidden', xs: 'scroll' },
                                    overflowY: { sm: 'auto', xs: 'hidden' },
                                    pr: 1,
                                    pb: { sm: 1, xs: 0 },
                                    '::-webkit-scrollbar': {
                                        width: '5px',
                                        height: {
                                            sm: 'auto',
                                            xs: '5px',
                                        },
                                    },
                                    '::-webkit-scrollbar-track': {
                                        background: `${theme.palette.background.borderLight}`,
                                        borderRadius: '10px',
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: `${theme.palette.background.medium}`,
                                        borderRadius: '10px',
                                    },
                                    '::-webkit-scrollbar-thumb:hover': {
                                        background: `${theme.palette.background.medium}`,
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        minWidth: { xs: '800px', sm: '100%' },
                                        display: 'flex',
                                        flexWrap: { sm: 'wrap', xs: 'no-wrap' },
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 1,
                                    }}
                                >
                                    {networks?.map((item, index) => (
                                        <Stack
                                            key={index}
                                            onClick={() => setNetwork(item)}
                                            direction="column"
                                            alignItems="center"
                                            sx={{
                                                cursor: 'pointer',
                                                gap: 0.5,
                                                py: 1.5,
                                                width: '80px',
                                                border: `1px solid ${theme.palette.background.borderLight}`,
                                                borderRadius: '10px',
                                                boxShadow: `0px 3px 9px ${theme.palette.background.shadow}`,
                                                transition: 'all 0.2s ease-in',
                                                background: `${theme.palette.background.medium}`,
                                                '&:hover': {
                                                    background: `${theme.palette.background.light}`,
                                                },
                                            }}
                                        >
                                            <img
                                                src={item.img}
                                                alt="coin"
                                                width="30px"
                                                height="30px"
                                            />

                                            <Typography fontSize="10px" fontWeight="400">
                                                {item.chain}
                                            </Typography>
                                        </Stack>
                                    ))}
                                </Box>
                            </Box>
                            <Stack
                                direction="column"
                                gap={2}
                                sx={{
                                    width: '100%',
                                    height: { sm: '100%', xs: '400px' },
                                    overflow: 'auto',
                                    pr: 1,
                                    pb: 1,
                                    '::-webkit-scrollbar': {
                                        width: '5px',
                                    },
                                    '::-webkit-scrollbar-track': {
                                        background: `${theme.palette.background.borderLight}`,
                                        borderRadius: '10px',
                                    },
                                    '::-webkit-scrollbar-thumb': {
                                        background: `${theme.palette.background.medium}`,
                                        borderRadius: '10px',
                                    },
                                    '::-webkit-scrollbar-thumb:hover': {
                                        background: `${theme.palette.background.medium}`,
                                    },
                                }}
                            >
                                {status === 'pending' ? (
                                    <p>Loading...</p>
                                ) : status === 'error' ? (
                                    <span>Error: {error.message}</span>
                                ) : (
                                    <>
                                        {data.pages?.map((page, index) => (
                                            <React.Fragment key={index}>
                                                {page.results
                                                    .filter((list) => {
                                                        return (
                                                            list.name !==
                                                            selectedToken[currentTokenSelection]
                                                                .name
                                                        );
                                                    })
                                                    .map((item, index) => (
                                                        <Stack
                                                            onClick={() => tokenChange(item)}
                                                            key={index}
                                                            direction="row"
                                                            alignItems="center"
                                                            sx={{
                                                                cursor: 'pointer',
                                                                gap: 2,
                                                                py: 1.5,
                                                                pl: 3,
                                                                pr: 3,
                                                                border: `1px solid ${theme.palette.background.borderLight}`,
                                                                borderRadius: '10px',
                                                                boxShadow: `0px 3px 9px ${theme.palette.background.shadow}`,
                                                                transition: 'all 0.2s ease-in',
                                                                background: `${theme.palette.background.medium}`,
                                                                '&:hover': {
                                                                    pl: 4,
                                                                    gap: 2.5,
                                                                    background: `${theme.palette.background.light}`,
                                                                },
                                                            }}
                                                        >
                                                            {item.image ? (
                                                                <img
                                                                    src={item?.image}
                                                                    alt="coin"
                                                                    width="30px"
                                                                    height="30px"
                                                                />
                                                            ) : (
                                                                <Token />
                                                            )}
                                                            <Stack direction="column">
                                                                <Typography
                                                                    fontSize="14px"
                                                                    fontWeight="600"
                                                                >
                                                                    {item?.name}
                                                                </Typography>
                                                                <Typography
                                                                    fontSize="11px"
                                                                    fontWeight="400"
                                                                    color="#C3C1C1"
                                                                >
                                                                    {item?.symbol}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                        <Button
                                            ref={ref}
                                            onClick={() => fetchNextPage()}
                                            disabled={!hasNextPage || isFetchingNextPage}
                                            sx={{
                                                mx: 'auto',
                                            }}
                                        >
                                            {isFetchingNextPage
                                                ? 'Loading more...'
                                                : hasNextPage
                                                ? 'Load Newer'
                                                : 'Nothing more to load'}
                                        </Button>
                                        <Typography
                                            sx={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            {isFetching && !isFetchingNextPage
                                                ? 'Loading...'
                                                : null}
                                        </Typography>
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </Dialog>
        </>
    );
}

import { createContext, useEffect, useMemo, useState } from 'react';
import { responsiveFontSizes } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { BLOCKCHAIN_NAME, CHAIN_TYPE } from 'rubic-sdk';
import { useAccount } from 'wagmi';
import { useNavigate } from 'react-router-dom';

import { createCustomTheme } from './createCustomTheme';

export const DataContext = createContext();

const ContextAPI = (props) => {
    // background custom
    const navigate = useNavigate();
    const [customBackground, setCustomBackground] = useState(localStorage.getItem('bg'));    
    const [isJupiter,setIsJupiter] = useState(localStorage.getItem('jupiter') ? true:  false);
    const [isChart, setIsChart] = useState(localStorage.getItem('chart') == 'tradingView' ? true : false);

    useEffect(() => {
        const user = localStorage.getItem('name');
        setUsername(user);
        if (!customBackground) navigate('/OnBoard');
        // else navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customBackground]);

    // ========
    const { address } = useAccount();
    // loader
    const [loader, setLoader] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const [username, setUsername] = useState('');
    // ================
 

    // theme
    const [mode, setMode] = useState('dark');

    const toggleMode = () => {
        setMode((val) => (val === 'dark' ? 'light' : 'dark'));
        localStorage.setItem('mode', mode);
    };

    const themeClient = useMemo(() => {
        let theme = createCustomTheme(mode);
        theme = responsiveFontSizes(theme);
        return theme;
    }, [mode]);
    // ================

    // token list
    const [network, setNetwork] = useState({
        name: 'ethereum',
        img: 'https://app.rubic.exchange/assets/images/icons/coins/eth-contrast.svg',
    });
    const [openList, setOpenList] = useState(false);
    const [currentTokenSelection, setCurrentTokenSelection] = useState('token1');
    const [selectedToken, setSelectedToken] = useState({
        token1: {
            name: 'Wrapped Ether',
            symbol: 'WETH',
            image: 'https://assets.rubic.exchange/assets/ethereum/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/logo.png',
            decimals: 18,
            address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
            network: 'ethereum',
        },
        token2: {
            name: 'Tether USD',
            symbol: 'USDT',
            image: 'https://assets.rubic.exchange/assets/ethereum/0xdac17f958d2ee523a2206206994597c13d831ec7/logo.png',
            decimals: 6,
            address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
            network: 'ethereum',
        },
    });

    const switchTokens = () => {
        setSelectedToken((prev) => {
            return {
                token1: {
                    ...prev.token2,
                },
                token2: {
                    ...prev.token1,
                },
            };
        });
    };

    const toggleList = () => setOpenList(!openList);

    // ================

    // rubic sdk

    const config = {
        rpcProviders: {
            [BLOCKCHAIN_NAME.POLYGON]: {
                rpcList: ['https://polygon-rpc.com', 'https://polygon.llamarpc.com'],
            },
            [BLOCKCHAIN_NAME.ETHEREUM]: {
                rpcList: ['https://1rpc.io/eth', 'https://eth.drpc.org'],
            },
            [BLOCKCHAIN_NAME.BINANCE_SMART_CHAIN]: {
                rpcList: ['https://bsc-dataseed.binance.org', 'https://bsc.drpc.org/'],
            },
            [BLOCKCHAIN_NAME.ARBITRUM]: {
                rpcList: [
                    'https://arbitrum.drpc.org',
                    'https://arbitrum.blockpi.network/v1/rpc/public',
                ],
            },
            [BLOCKCHAIN_NAME.AURORA]: {
                rpcList: ['https://aurora.drpc.org/', ''],
            },
            [BLOCKCHAIN_NAME.AVALANCHE]: {
                rpcList: ['https://api.avax.network/ext/bc/C/rpc', 'https://avalanche.drpc.org/'],
            },
            [BLOCKCHAIN_NAME.TELOS]: {
                rpcList: ['https://mainnet.telos.net/evm', ''],
            },
            [BLOCKCHAIN_NAME.FANTOM]: {
                rpcList: ['https://rpc.ftm.tools/', 'https://fantom.drpc.org/'],
            },
            [BLOCKCHAIN_NAME.MOONRIVER]: {
                rpcList: [
                    'https://moonriver.api.onfinality.io/public',
                    'https://rpc.moonriver.moonbeam.network',
                ],
            },
        },
        walletProvider: {
            [CHAIN_TYPE.EVM]: {
                core: window.ethereum,
                address,
            },
        },
        providerAddress: {
            [CHAIN_TYPE.EVM]: {
                crossChain: '0x6494E403bEF9c2D30A7859a05C67a61A057db9bE',
                onChain: '0x6494E403bEF9c2D30A7859a05C67a61A057db9bE',
            },
        },
    };

    // ================

    //swap
    const [swapDeposit1, setSwapDeposit1] = useState();
    const [swapDeposit2, setSwapDeposit2] = useState();

    // ================

    // react query

    const queryClient = new QueryClient();

    // ================
    return (
        <DataContext.Provider
            value={{
                // background
                customBackground,
                setCustomBackground,
                // ========
                // loader
                loader,
                setLoader,
                config,
                // ========
                // theme
                mode,
                setMode,
                themeClient,
                toggleMode,
                // ========
                //token list
                network,
                setNetwork,
                currentTokenSelection,
                setSelectedToken,
                selectedToken,
                toggleList,
                openList,
                switchTokens,
                setCurrentTokenSelection,
                // ========
                // swap
                swapDeposit1,
                setSwapDeposit1,
                swapDeposit2,
                setSwapDeposit2,
                // ========
                // react query
                queryClient,
                openProfile,
                setOpenProfile,
                username,
                setUsername,
                //Charts
                isJupiter,
                setIsChart,
                isChart,
                setIsJupiter
                // ========

            }}
        >
            {props.children}
        </DataContext.Provider>
    );
};

export default ContextAPI;
import React from 'react';
import { SvgIcon } from '@mui/material';

const DiscordSVG = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.9762 5.33005C18.6462 4.71005 17.2062 4.26005 15.7062 4.00005C15.693 3.99963 15.6799 4.00209 15.6678 4.00728C15.6557 4.01246 15.6449 4.02023 15.6362 4.03005C15.4562 4.36005 15.2462 4.79005 15.1062 5.12005C13.5152 4.88005 11.8972 4.88005 10.3062 5.12005C10.1662 4.78005 9.95617 4.36005 9.76617 4.03005C9.75617 4.01005 9.72617 4.00005 9.69617 4.00005C8.19617 4.26005 6.76617 4.71005 5.42617 5.33005C5.41617 5.33005 5.40617 5.34005 5.39617 5.35005C2.67617 9.42005 1.92617 13.38 2.29617 17.3C2.29617 17.32 2.30617 17.34 2.32617 17.35C4.12617 18.67 5.85617 19.47 7.56617 20C7.59617 20.01 7.62617 20 7.63617 19.98C8.03617 19.43 8.39617 18.85 8.70617 18.24C8.72617 18.2 8.70617 18.16 8.66617 18.15C8.09617 17.93 7.55617 17.67 7.02617 17.37C6.98617 17.35 6.98617 17.29 7.01617 17.26C7.12617 17.18 7.23617 17.09 7.34617 17.01C7.36617 16.99 7.39617 16.99 7.41617 17C10.8562 18.57 14.5662 18.57 17.9662 17C17.9862 16.99 18.0162 16.99 18.0362 17.01C18.1462 17.1 18.2562 17.18 18.3662 17.27C18.4062 17.3 18.4062 17.36 18.3562 17.38C17.8362 17.69 17.2862 17.94 16.7162 18.16C16.6762 18.17 16.6662 18.22 16.6762 18.25C16.9962 18.86 17.3562 19.44 17.7462 19.99C17.7762 20 17.8062 20.01 17.8362 20C19.5562 19.47 21.2862 18.67 23.0862 17.35C23.1062 17.34 23.1162 17.32 23.1162 17.3C23.5562 12.77 22.3862 8.84005 20.0162 5.35005C20.0062 5.34005 19.9962 5.33005 19.9762 5.33005ZM9.22617 14.91C8.19617 14.91 7.33617 13.96 7.33617 12.79C7.33617 11.62 8.17617 10.67 9.22617 10.67C10.2862 10.67 11.1262 11.63 11.1162 12.79C11.1162 13.96 10.2762 14.91 9.22617 14.91ZM16.1962 14.91C15.1662 14.91 14.3062 13.96 14.3062 12.79C14.3062 11.62 15.1462 10.67 16.1962 10.67C17.2562 10.67 18.0962 11.63 18.0862 12.79C18.0862 13.96 17.2562 14.91 16.1962 14.91Z"
        fill="#618DEB"
      />
    </SvgIcon>
  );
};

export default DiscordSVG;

import React from 'react';
import { SvgIcon } from '@mui/material';

const MediumSVG = (props) => {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_206_225)">
        <path
          d="M8.70605 6C10.2974 6 11.8235 6.63214 12.9487 7.75736C14.0739 8.88258 14.7061 10.4087 14.7061 12C14.7061 13.5913 14.0739 15.1174 12.9487 16.2426C11.8235 17.3679 10.2974 18 8.70605 18C7.11476 18 5.58863 17.3679 4.46341 16.2426C3.3382 15.1174 2.70605 13.5913 2.70605 12C2.70605 10.4087 3.3382 8.88258 4.46341 7.75736C5.58863 6.63214 7.11476 6 8.70605 6ZM17.7061 7C19.2061 7 20.2061 9.239 20.2061 12C20.2061 14.761 19.2061 17 17.7061 17C16.2061 17 15.2061 14.761 15.2061 12C15.2061 9.239 16.2061 7 17.7061 7ZM21.7061 7.5C22.0861 7.5 22.4181 8.327 22.5861 9.746L22.6331 10.189L22.6521 10.424L22.6821 10.918L22.6921 11.177L22.7041 11.718L22.7061 12L22.7041 12.282L22.6921 12.823L22.6821 13.083L22.6521 13.576L22.6321 13.811L22.5871 14.254C22.4181 15.674 22.0871 16.5 21.7061 16.5C21.3261 16.5 20.9941 15.673 20.8261 14.254L20.7791 13.811C20.7722 13.7327 20.7658 13.6544 20.7601 13.576L20.7301 13.082C20.7262 12.9957 20.7229 12.9094 20.7201 12.823L20.7081 12.282V11.718L20.7201 11.177L20.7301 10.917L20.7601 10.424L20.7801 10.189L20.8251 9.746C20.9941 8.326 21.3251 7.5 21.7061 7.5Z"
          fill="#618DEB"
        />
      </g>
      <defs>
        <clipPath id="clip0_206_225">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.706055)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default MediumSVG;
